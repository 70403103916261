<template>
  <div>
    <article class="p-4 sm:border sm:rounded-md">
      <div class="flex animate-pulse">
        <div class="h-24 w-24 bg-shimmer rounded-md"></div>
        <div class="ms-4 flex-grow">
          <div class="h-4 bg-shimmer rounded dark:bg-gray-850"></div>
          <div class="h-4 bg-shimmer rounded dark:bg-gray-850 mt-1 w-3/4"></div>
          <div class="flex">
            <div
              class="h-5 bg-shimmer rounded dark:bg-gray-850 mt-2 w-16"
            ></div>
            <div
              class="ms-1 h-5 bg-shimmer rounded dark:bg-gray-850 mt-2 w-12"
            ></div>
          </div>
        </div>
      </div>
      <div class="flex mt-2 justify-between animate-pulse">
        <div>
          <div class="h-5 w-16 bg-shimmer rounded dark:bg-gray-850"></div>
          <div class="h-2 bg-shimmer rounded dark:bg-gray-850 mt-1 w-12"></div>
        </div>
        <div class="w-32 rounded dark:bg-gray-850 bg-shimmer"></div>
      </div>
    </article>
  </div>
</template>
